.birthday-card-container {
  position: relative;
  width: 1200px;
  height: 1500px;
  color: black;

  .birthday-frame {
    object-fit: cover;
    z-index: 0;
    position: absolute;
  }

  .birthday-card-info-container {
    font-family: 'BMKing001-Regular';

    .agent-profile {
      z-index: -1;
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      width: 553px;
      height: 735px;
      // opacity: 80%;
      &.v1 {
        bottom: 20%;
        left: 5%;
      }
      &.v2{
        bottom: 19%;
        left: 6%;
      }
    }

    .date-month {
      position: absolute;
      font-size: 50px;
      font-weight: 500;
      right: 10%;

      &.birthday-card-one {
        top: 12%;
      }

      &.birthday-card-two {
        top: 16%;
      }
    }

    .agent-info {
      position: absolute;
      width: 100%;
      text-align: center;
      &.agent-name {
        font-size: 62px;
        font-weight: bold;
        left: -24%;
        top: 83%;
        text-align: center;
        letter-spacing: -2px;
        // &.birthday-card-one{
        //   top: 80%;
        // }
        // &.birthday-card-two{
        //   top: 82%;
        // }
      }
      &.myan_font {
        font-size: 62px;
      }
      &.more-twenty {
        font-size: 54px;
        // left: 2%;
      }

      &.agent-code {
        font-size: 54px;
        font-weight: bold;
        left: -25%;
        top: 89%;
        text-align: center;
        // &.birthday-card-one{
        //   top: 85%;
        // }

        // &.birthday-card-two{
        //   top: 87%;
        // }
      }
    }
    .white {
      color: #eaeaea;
    }
    .black {
      color: #000000;
    }
    .green {
      color: #525252;
    }
  }
}
