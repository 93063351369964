.count_container {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  margin-bottom: 10px;
  margin-top: 19px;
}

.count_badge {
  font-family: Roboto, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 12px;
  vertical-align: middle;
  box-sizing: border-box;
  color: rgb(33, 150, 243);
  background-color: rgb(227, 242, 253);
}
