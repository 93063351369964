.fa-pencil {
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 50px auto;
  .avatar-edit {
    position: absolute;
    right: 22px;
    z-index: 1;
    top: 10px;
    input {
      display: none;
      + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #ffffff;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: #96eca4;
          border-color: #96eca4;
          > svg {
            color: #fff;
          }
        }
      }
    }
  }
  .avatar-preview {
    animation-name: borderColor;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    width: 192px;
    height: 192px;
    margin-bottom: 12px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-size: cover;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.accept-image {
  line-height: 1.5;
  font-size: 0.86rem;
  font-weight: 400;
  color: rgb(99, 115, 129);
  display: block;
  text-align: center;
}

@keyframes borderColor {
  0% {
    border-color: #96eca4;
  }
  50% {
    border-color: #cad4ff;
  }
  100% {
    border-color: #96eca4;
  }
}
