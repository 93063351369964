.digital_bonus_certificate_container {
  position: relative;
  width: 1200px;
  height: 1697px;

  .trip_certificate_card {
    width: '100%';
    height: '100%';
    z-index: 10;
    position: absolute;
  }

  .agent_profile_container {
    width: 630px;
    height: 630px;
    position: absolute;
    z-index: 9;
    top: 13.5%;
    left: 23.5%;
    object-fit: cover;
    opacity: 0.9;
    .agent_profile {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .agent_name {
    z-index: 10;
    position: absolute;
    width: 1200px;
    height: 100px;
    top: 51.4%;
    left: 0.1%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Aleo_Medium';
    font-weight: 500;
    font-size: 85px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #efd931;
    text-shadow: -3px -3px 0 #4d0f11, 0 -3px 0 #4d0f11, 3px -3px 0 #4d0f11, 3px 0 0 #4d0f11,
      3px 3px 0 #4d0f11, 0 3px 0 #4d0f11, -3px 3px 0 #4d0f11, -3px 0 0 #4d0f11, 0 3px 16px #000000;
    &.mm_name {
      top: 51.4%;
      font-size: 60px;
    }
    &.more_fifteen {
      top: 51.7%;
      font-size: 70px;
    }
    &.more_twenty {
      top: 51.7%;
      font-size: 60px;
    }
    &.more_twenty_five {
      top: 51.7%;
      font-size: 55px;
    }
  }

  .agent_code {
    position: absolute;
    z-index: 13;
    top: 55.3%;
    left: 23.8%;
    font-family: 'Aleo_Medium';
    font-size: 65px;
    font-weight: 500;
    width: 620px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffd700;
    text-shadow: -3px -3px 0 #4d0f11, 0 -3px 0 #4d0f11, 3px -3px 0 #4d0f11, 3px 0 0 #4d0f11,
      3px 3px 0 #4d0f11, 0 3px 0 #4d0f11, -3px 3px 0 #4d0f11, -3px 0 0 #4d0f11, 0 3px 16px #000000;
  }

  .gold_coin {
    position: absolute;
    z-index: 13;
    color: #ffffff;
    text-align: center;
    top: 34.3%;
    left: 9%;
    width: 260px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: -0.5rem;
    font-family: 'w35art-house';
    font-size: 70px;
    text-shadow: -4px -4px 0 #490000, 0 -4px 0 #490000, 4px -4px 0 #490000, 4px 0 0 #490000,
      4px 4px 0 #490000, 0 4px 0 #490000, -4px 4px 0 #490000, -4px 0 0 #490000, 0 3px 16px #000000;
    &.group_two {
      top: 37%;
    }
  }

  .product_group_qty {
    position: absolute;
    z-index: 13;
    bottom: 23.3%;
    right: 30.7%;
    font-family: 'Aleo_Medium';
    font-size: 45px;
    font-weight: 500;
    width: 460px;
    height: 50px;
    color: #efd931;
    text-align: center;
  }
}
