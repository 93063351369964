.digital_trip_certificate_container {
  position: relative;
  width: 1200px;
  height: 1697px;

  .trip_certificate_card {
    width: '100%';
    height: '100%';
    z-index: 10;
    position: absolute;
  }

  .agent_profile_container {
    width: 658px;
    height: 651px;
    position: absolute;
    z-index: 9;
    left: 23.2%;
    top: 12.5%;
    object-fit: cover;
    opacity: 0.9;
    .agent_profile {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .agent_name {
    z-index: 10;
    position: absolute;
    width: 1200px;
    height: 170px;
    top: 52%;
    left: 0.1%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Aleo_Medium';
    font-weight: 500;
    font-size: 100px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: -5px -5px 0 #044369, 0 -5px 0 #044369, 5px -5px 0 #044369, 5px 0 0 #044369,
      5px 5px 0 #044369, 0 5px 0 #044369, -5px 5px 0 #044369, -5px 0 0 #044369, 0 3px 16px #000000;
    &.mm_name {
      top: 50.7%;
      font-size: 80px;
    }
    &.more_fifteen {
      top: 51.4%;
      font-size: 80px;
    }
    &.more_twenty {
      top: 52%;
      font-size: 70px;
    }
    &.more_twenty_five {
      top: 52%;
      font-size: 60px;
    }
  }

  .agent_code {
    position: absolute;
    z-index: 13;
    top: 59.2%;
    left: 25%;
    font-family: 'Aleo_Medium';
    font-size: 80px;
    font-weight: 500;
    width: 620px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-shadow: -5px -5px 0 #044369, 0 -5px 0 #044369, 5px -5px 0 #044369, 5px 0 0 #044369,
      5px 5px 0 #044369, 0 5px 0 #044369, -5px 5px 0 #044369, -5px 0 0 #044369, 0 3px 16px #000000;
  }

  .ticket {
    position: absolute;
    z-index: 13;
    color: #ffffff;
    text-align: center;
    top: 39%;
    right: 2.5%;
    width: 350px;
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: -0.5rem;
    font-family: 'w35art-house';
    font-size: 85px;
    text-shadow: -3px -3px 0 #015f9f, 0 -3px 0 #015f9f, 3px -3px 0 #015f9f, 3px 0 0 #015f9f,
      3px 3px 0 #015f9f, 0 3px 0 #015f9f, -3px 3px 0 #015f9f, -3px 0 0 #015f9f, 0 3px 16px #000000;
  }

  .product_group_qty {
    position: absolute;
    z-index: 13;
    bottom: 25.7%;
    right: 23.4%;
    font-family: 'Aleo_Medium';
    font-size: 50px;
    font-weight: 500;
    width: 350px;
    height: 100px;
    color: #ffffff;
    text-align: center;
  }
}
