.root_container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 24px;
}

.root__title {
  margin: 0px 0px 0.35em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
}

.root__description {
  margin: 8px 0px 0px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  color: rgb(101, 116, 139);
}

.line_right {
  border-right: 2px solid rgb(230, 232, 240);
}

.line_break {
  margin: 24px 0px;
  flex-shrink: 0;

  border-bottom: 2px solid rgb(230, 232, 240);
}

.root__border_right {
  border-right: 2px solid rgb(230, 232, 240);
}

.btn {
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 6px;
  border: none;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0px 8px;
  font-size: 0.75rem;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.btn_success {
  color: rgb(34, 154, 22);
  background-color: rgba(84, 214, 44, 0.16);
}

.btn_danger {
  color: rgb(183, 33, 54);
  background-color: rgba(255, 72, 66, 0.16);
}

.agent_code__btn {
  margin-bottom: 20px;
  height: 22px;
  min-width: 22px;
  line-height: 0;
  border-radius: 6px;
  border: none;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 15px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #6e5c5c;
  background-color: rgb(252 161 245 / 16%);
}
