.product_trip_certificate_container {
  position: relative;
  width: 2427px;
  height: 3535px;

  .trip_certificate_card {
    width: '100%';
    height: '100%';
    z-index: 10;
    position: absolute;
  }

  .bonus_month {
    z-index: 10;
    position: absolute;
    left: 50.7%;
    top: 10.5%;
    color: #000;
    font-family: 'NexaBold';
    font-size: 55px;
    transform: rotate(-6deg);
    filter: drop-shadow(3px 3px 5px rgba(1, 65, 77, 0.64));
  }

  .agent_profile_container {
    width: 1352px;
    height: 1586px;
    position: absolute;
    z-index: 9;
    left: 23%;
    top: 9.8%;
    opacity: 0.9;
    .agent_profile {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .agent_name {
    z-index: 10;
    position: absolute;
    width: 65%;
    height: 170px;
    top: 53%;
    left: 18.3%;
    font-size: 100px;
    line-height: 113px;
    text-transform: uppercase;
    font-family: 'AsturoGrandSerif';
    font-weight: 400;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #012195;
    letter-spacing: 5px;
    &.mm_font {
      font-size: 80px;
      letter-spacing: -2px;
      font-weight: 700;
    }
    &.eng_font {
      &.length-over-20 {
        font-size: 88px;
      }
    }
  }

  .agent_code {
    position: absolute;
    z-index: 13;
    left: 8.5%;
    top: 57.8%;
    font-size: 89px;
    line-height: 105px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    font-family: 'AsturoGrandSerif';
    width: 1200px;
    height: 137px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amount {
    position: absolute;
    bottom: 22.6%;
    z-index: 13;
    color: #000;
    text-align: center;
    font-family: 'BMking-007';
    font-weight: normal;
    transform: translate(-22%, 94%);
    &.prize {
      width: 640px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 35.4%;
      font-size: 80px;
      line-height: 1766px;
      // transform: translate(-43%, 50%);
      word-spacing: -0.5rem;
      bottom: 24.5%;
    }
    &.ticket {
      width: 500px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 8%;
      right: 43.8%;
      word-spacing: -0.5rem;
      font-size: 65px;
      line-height: 2012px;
    }
    // &.mm {
    // transform: translate(-22%, 94%);
    // }
    &.th {
      font-family: 'AsturoGrandSerif';
      font-style: italic;
    }
  }

  .product_group_qty {
    font-family: 'AsturoGrandSerif';
    position: absolute;
    z-index: 13;
    bottom: 28%;
    left: 67%;
    // font-weight: lighter;
    width: 500px;
    height: 120px;
    font-weight: 400;
    color: #000;
    text-align: center;
    font-size: 62px;
    line-height: 323px;
    text-transform: uppercase;
    // font-style: italic;
    text-align: center;
    letter-spacing: 2px;
  }

  .three_months_range {
    position: absolute;
    color: #1f9996;
    z-index: 13;
    // width: 100px;
    font-family: 'Montserrat_VariableFont_wght';
    right: 130px;
    bottom: 40px;
    font-size: 55px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    text-align: right;
    line-height: 50px;
    .trip_months {
      font-size: 45px;
    }
    .year {
      font-weight: bold;
      font-size: 55px;
      line-height: 80px;
    }
  }
}
