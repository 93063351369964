/** If you want to override variables do it here */
// @import 'variables';

// $enable-ltr: true;
// $enable-rtl: true;

// Import CoreUI for React components library
@import '../../node_modules/@coreui/coreui/scss/coreui.scss';

// Import Google font
@import url('https://fonts.googleapis.com/css?family=Nunito&sans-serif');

@import 'layout';
// @import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';

@import '_april_certificate_card';

@import '_product_trip_certificate';

@import '_six_month_bonus_certificate.scss';

@import '_year_end_trip_certificate_card.scss';

@import '_year_end_bonus_certificate_card.scss';

@import './birthday_card'
